* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
button {
  outline: none;
  border: none;
  font-size: initial;
  font-style: inherit;
  font-family: Lato, sans-serif;
}