.sw-logo {
  display: flex;
  width: fit-content;
  align-items: center;
  &_svg {
    width: 3rem;
    height: 3rem;
  }
  &_label {
    font-size: 1.8rem;
    font-weight: bold;
    padding-left: 1.5rem;
    color: #000;
  }
}