.sw-btn {
  display: inline-block;
  outline: none;
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 1rem 1.4rem ;
  border: none;
  border-radius: .6rem;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  align-items: center;
  > *:not(:last-child)  {
    margin-right: 1rem;
  }
  &:hover {
    @include shadow()
  }
  &_link {
    @extend .sw-btn;
    padding: 0;
    color: $color-primary;
    &:hover {
      box-shadow: none;
    }
  }
  &_primary {
    @extend .sw-btn;
    color: $color-primary-lite;
    background: $color-primary;
    &--invert {
      @extend .sw-btn;
      color: $color-primary;
      background: $color-primary-lite;
    }
  }
  &_success {
    @extend .sw-btn;
    color: $color-success-lite;
    background: $color-success;
    &--invert {
      @extend .sw-btn;
      color: $color-success;
      background: $color-success-lite;
    }
  }
  &_warning {
    @extend .sw-btn;
    color: $color-warning-lite;
    background: $color-warning;
    &--invert {
      @extend .sw-btn;
      color: $color-warning;
      background: $color-warning-lite;
    }
  }
  &_danger {
    @extend .sw-btn;
    color: $color-danger-lite;
    background: $color-danger;
    &--invert {
      @extend .sw-btn;
      color: $color-danger;
      background: $color-danger-lite;
    }
  }
}

