.sw-menu {
  // display: flex;
  list-style: none;
  &_item {
    display: block;
    padding: 1rem 3rem;
    font-weight: 600;
    position: relative;
    user-select: none;
    &::after {
      content: "";
      position: absolute;
      height: 0%;
      width: 5px;
      background-color: currentColor;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: height 200ms ease;
    }
    &:hover {
      cursor: default;
      &::after {
        height: 100%;
      }
    }
    &--active {
      @extend .sw-menu_item;
      color: #0088FF;
      &::after {
        height: 100%;
        background-color: currentColor;
      }
      pointer-events: none;
    }
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}