.sw-card {
  display: inline-block;
  border: 1px solid $color-gray-4;
  padding: 1rem;
  @include shadow();
  &--clickable {
    @extend .sw-card;
    &:hover{
      cursor: pointer;
    }
  }
  &--disabled {
    @extend .sw-card;
    filter: grayscale(1);
    color: $color-gray-2 !important;
    box-shadow: none;
  }
}