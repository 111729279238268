

// COLORS
$color-white: #FFFFFF;
$color-black: #000000;

$color-gray  : #40404B;
$color-gray-2: #919191;
$color-gray-3: #D8D8D8;
$color-gray-4: #EAEAEA;

$color-danger: #FF5A5A;
$color-danger-lite: #FFCFCF;

$color-warning: #FFB400;
$color-warning-lite: #FFE8B2;

$color-primary: #0088FF;
$color-primary-lite: #BCE0FF;

$color-success: #90BE32;
$color-success-lite: #DAF4A6;

// Z INDEX
$z-index-modal: 999;
$z-index-header: 998;






