.sw-section {
  position: relative;
  &:not(:last-child){
    margin-bottom: 10rem;
  }
  &_label {
    // padding-top: 8rem;
    padding-bottom: 4rem;
    font-weight: bold;
    // position: sticky;
    // top: 0;
    z-index: 1;
    background-color: $color-white;
  }
  &_content {
    display: flex;
    flex-wrap: wrap;
    > * {
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
  }
}