@import '../../SCSS/mixins/shadow';
.sw-canvas {
  width: 55rem;
  height: 100%;
  // background-color: blue;
  overflow-y: auto;
  @include shadow(lg);
  border-radius: 1rem;
  padding: 0;
  position: relative;
  &_ref {
    height: 100%;
    width: 100%;
  }
  &_controls {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.3);
    right: 1rem;
    border-radius: 0.6rem;
  }
}