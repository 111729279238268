@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap');

html {
  font-size: 62.5%; //10px
}

body {
  font-family: Lato, -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  color: $color-gray;
}
// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
b {
  font-weight: 800;
}
h1 {
  font-size: 3.5rem;
  line-height: 4.2rem;
  font-weight: inherit;
}
h2 {
  font-size: 2.6rem;
  line-height: 3.2rem;
  font-weight: inherit;
}
h3 {
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: inherit;
}
h5 {
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-weight: inherit;
}
p {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: inherit;
}
