.sw-snapcard {
  display: inline-block;
  width: 24rem;
  border-radius: 0.8rem;
  overflow: hidden;
  padding: 0;
  color: $color-gray;
  &--hoverable {
    @extend .sw-snapcard;
    transition: all 200ms ease-in-out;
    &:hover {
      transform:  scale(1.02)
                  translateY(-3px);
      transform-origin: center;
      @include shadow(lg);
      cursor: pointer;
    }
  }
  &_head {
    position: relative;
    height: 17rem;
    color: inherit;
    padding: 1.4rem;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    &_tint {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
    }
  }
  &_title {
    position: absolute;
    bottom: 2rem;
    font-weight: bold;
  }
  &_body {
    position: relative;
    padding: 1.4rem;
    padding-top: 0;
    height: 15rem;
    background-color: $color-warning;
    color: inherit;
    padding-bottom: 3rem;
  }
  &_timestamp {
    position: absolute;
    left: 1.4rem;
    bottom: 2rem;
    opacity: .6;
    font-weight: bold;
  }
}